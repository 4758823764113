import { Grid } from "@mui/material";
import Home from "pages/Home";
import Privacy from "pages/Privacy";
import { Component, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./style.css";

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/privacy">
            <Suspense fallback={<div></div>}>
              <Grid>
                <Privacy />
              </Grid>
            </Suspense>
          </Route>
          <Route path="/">
            <Suspense fallback={<div></div>}>
              <Grid>
                <Home />
              </Grid>
            </Suspense>
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default App;
